import io from "socket.io-client";

const storageLiterals = {
    "CONVERSATION_ID": "conversationId",
    "LAST_RECEIVED_DATE": "lastReceivedMessageDate",
    "LAST_CHANNEL": "lastChannel",
    "LAST_ACTOR": "lastActor",
    "CURR_USER_CONTEXT": "currUserContext",
    "LAST_AGENT_NAME": "lastAgentName",
    "BOT_RETRIES": "botRetries",
    "PREVIOUS_CONVERSATION_ID": "previousConversationId",
}
class Lia {
    constructor(channel, localStorageTranslation) {

        this.actor = "";
        this.userId = "";
        if (!channel) {
            throw Error("Channel is essential for chat initialization");
        }
        this.channel = channel;

        this.connected = false;
        if (!localStorageTranslation) {
            throw Error("Missing localStorageTranslation for chat initialization");
        }

        this.storage = {
            set: (key, value) => { localStorageTranslation.set(localStorageTranslation.prefix + "_" + key, value); },
            get: (key) => { return localStorageTranslation.get(localStorageTranslation.prefix + "_" + key); },
            remove: (key) => { localStorageTranslation.remove(localStorageTranslation.prefix + "_" + key); },
        }
    }

    connect = (mode) => {
        if (mode == "CONNECT_AGAIN" && this.customerSuportSocket) {
            this.customerSuportSocket.close();
        } 

        const isBeta = /((beta)|(^home\.))/i.test(window.location.hostname)
        const isDev = /(local)|(192.168.1)/i.test(window.location.hostname)

        const baseUrl = isDev ? ("localhost:3301/customer_support") :
            isBeta ? "https://lia-beta.finanzero.com.br/customer_support" :
                "https://lia.finanzero.com.br/customer_support";
        this.customerSuportSocket = io.connect(baseUrl, {
            path: "/websocket", query: {
            conversationId: this.storage.get(storageLiterals.CONVERSATION_ID)
        }});
        if (mode != "CONNECT_AGAIN" && this.storage.get(storageLiterals.CONVERSATION_ID)) {
            this.askForHistory("FROM_START");
        }
        this.attachEventCallbacks();
    }
    attachEventCallbacks = () => {
        this.customerSuportSocket.on("connect", () => {
            this.changeConnectionStatus(true);
        });

        this.customerSuportSocket.on("messageFromServer", this.processMessageFromServer);
        this.customerSuportSocket.on("notificationFromServer", this.processNotificationFromServer);

        this.customerSuportSocket.on("reconnect", () => {
            this.askForHistory("FROM_LAST_MESSAGE_RECEIVED");
            this.changeConnectionStatus(true);
        });
        this.customerSuportSocket.on('disconnect', (reason) => {
            console.log("socket was connected with reason: ", reason);
            this.changeConnectionStatus(false);
        });
        this.customerSuportSocket.on('reconnect_attempt', (reason) => {
            console.log("socket trying to reconnect: ", reason);
        });
    }
    connectIfDisconnected = () => {
        if (!this.connected) {
            this.connect();
        }
    }
    finishConversation = () => {
        const previousConversationId = this.storage.get(storageLiterals.CONVERSATION_ID);
        const storageItemsToRemove = Object.values(storageLiterals);

        for (const key of storageItemsToRemove) {
            this.storage.remove(key);
        }
        this.storage.set(storageLiterals.PREVIOUS_CONVERSATION_ID, previousConversationId);
        this.onReceivedCallback({
            action: "changedConversationId",
            previous: previousConversationId,
            current: null
        });
    }
    getStorage = () => {
        const storage = {};
        const storageItems = Object.values(storageLiterals);
        for (const key of storageItems) {
            storage[key] = this.storage.get(key);
        }
        return storage;
    }
    setCurrUserContext = (currUserContext) => {
        this.storage.set(storageLiterals.CURR_USER_CONTEXT, JSON.stringify(currUserContext));
    }
    getCurrUserContext = () => {
        return JSON.parse(this.storage.get(storageLiterals.CURR_USER_CONTEXT));
    }
    changeConnectionStatus = (connected) => {
        this.connected = connected;
        if (!this.onReceivedCallback) return

        this.onReceivedCallback({
            action: 'changedConnectedStatus',
            current: this.connected
        });
    };
    askForHistory = (mode, testDate) => {
        let queryDate = null;

        switch (mode) {
            case "FROM_START":
                const date = new Date();
                date.setDate(date.getDate()-1);
                queryDate = date.toISOString();
                break;//TODO: passar data antiga
            case "FROM_LAST_MESSAGE_RECEIVED": queryDate = this.storage.get(storageLiterals.LAST_RECEIVED_DATE); break;
        }

        if (queryDate || testDate) {//TODO: remove test date after testing
            this.customerSuportSocket.emit("clientReconnected", {
                conversationId: this.storage.get(storageLiterals.CONVERSATION_ID),
                date: testDate || queryDate
            });
        }
    }
    getConnected = () => {
        const connected = this.connected;

        return connected;
    }

    getLastAgentName = () => {
        return this.storage.get(storageLiterals.LAST_AGENT_NAME);
    }
    getLastActor = () => {
        return this.storage.get(storageLiterals.LAST_ACTOR);
    }
    getLastChannel = () => {
        return this.storage.get(storageLiterals.LAST_CHANNEL);
    }
    getLastConversationId = () => {
        return this.storage.get(storageLiterals.CONVERSATION_ID);
    }
    sendPositiveEvaluation = (channel) => {
        const previousConversationId = this.storage.get(storageLiterals.PREVIOUS_CONVERSATION_ID);
        if (previousConversationId) {
            this.sendNotification("rating_conversation", channel , "human", "POSITIVE", previousConversationId);
        }
    }
    sendNegativeEvaluation = (channel) => {
        const previousConversationId = this.storage.get(storageLiterals.PREVIOUS_CONVERSATION_ID);
        if (previousConversationId) {
            this.sendNotification("rating_conversation", channel , "human", "NEGATIVE", previousConversationId);
        }
    }
    sendNotification = (action, channel, actor, value, conversationId ) => {
        const messageObj = {
            action: action,
            value: value || null,
            conversationId: conversationId || this.storage.get(storageLiterals.CONVERSATION_ID),
            channel: channel || this.channel,
            actor: actor || this.actor,
            liaLastActor: this.storage.get(storageLiterals.LAST_ACTOR),
            liaLastChannel: this.storage.get(storageLiterals.LAST_CHANNEL),
            botRetries: this.storage.get(storageLiterals.BOT_RETRIES) || 0,
            channelDate: (new Date()).toISOString(),
            date: (new Date()).toISOString(),
            userContext: this.getCurrUserContext(),
            testWith: this.storage.get("chatPartner")
        }
        if (!messageObj.testWith) {
            console.error('O Chat partnert não foi definido! Use this.storage.set("chatPartner", "watson"|"zenvia")');
        }
        this.customerSuportSocket.emit("notificationToServer", messageObj);
        return messageObj;
    }
    send = (action, channel, message, actor, conversationId, userId) => {
        const messageObj = {
            action: action || "message",
            conversationId: conversationId || this.storage.get(storageLiterals.CONVERSATION_ID),
            channel: channel || this.channel,
            actor: actor || this.actor,
            liaLastActor: this.storage.get(storageLiterals.LAST_ACTOR),
            liaLastChannel: this.storage.get(storageLiterals.LAST_CHANNEL),
            message: message,
            botRetries: this.storage.get(storageLiterals.BOT_RETRIES) || 0,
            channelDate: (new Date()).toISOString(),
            date: (new Date()).toISOString(),
            userContext: this.getCurrUserContext(),
            testWith: this.storage.get("chatPartner")
        }
        if (!messageObj.testWith) {
            console.error('O Chat partner não foi definido! Use this.storage.set("chatPartner", "watson"|"zenvia")');
        }
        this.customerSuportSocket.emit("messageToServer", messageObj);
        return messageObj;
    }
    incrementBotRetries = () => {
        if (!this.storage.get(storageLiterals.BOT_RETRIES)) {
            this.storage.set(storageLiterals.BOT_RETRIES, 1);
        } else {
            this.storage.set(storageLiterals.BOT_RETRIES, Number(this.storage.get(storageLiterals.BOT_RETRIES)) + 1 );
        }
    }
    restartBotRetries = () => {
        this.storage.remove(storageLiterals.BOT_RETRIES);
    }
    processNotificationFromServer = (notificationFromServer) => {
        if (!notificationFromServer.action) {
            console.error("TODO: notification from server is missing 'action'. 'action' is essential for any notification coming from server.", notificationFromServer);
        }
        if (!this.onReceivedCallback || !notificationFromServer || !notificationFromServer.action) {
            return;
        }
        if (notificationFromServer.action == "finished") {
            this.finishConversation();
        }
        this.onReceivedCallback(notificationFromServer);
    }
    processMessageFromServer = (messageFromServer) => {
        if (!messageFromServer.action) {
            console.error("TODO: message from server is missing 'action'. 'action' is essential for any message coming from server.", messageFromServer);
        }
        if (!messageFromServer.channel) {
            console.error("TODO: message from server is missing 'channel'. 'channel' is essential for parati to accept most messages.", messageFromServer);
        }
        if (messageFromServer.channel && typeof messageFromServer.channel != 'string' && !(messageFromServer.channel instanceof String)) {
            console.error("TODO: message from server is missing 'channel'. 'channel' has to be of type STRING. it is essential for parati to accept most messages.", messageFromServer);
        }
        if (messageFromServer.channel == "zenvia" && !messageFromServer.agentName) {
            console.warn("TODO: message from server is missing 'agentName'. 'agentName' is essential for user to see.", messageFromServer);
        }
        if (!messageFromServer.actor) {
            console.warn("TODO: message from server is missing 'actor'", messageFromServer);
        }
        if (!messageFromServer.date && messageFromServer.action && (messageFromServer.action == "message" || messageFromServer.action == "options")) {
            console.error("TODO: message from server is missing 'date'. 'date' is essential for retrieving pending messages by delta", messageFromServer);
        }

        if (!this.onReceivedCallback || !messageFromServer || !messageFromServer.action) {
            return;
        }
        if (messageFromServer.action != "history") {//TODO: check for user id, type etc
            this.onReceivedCallback(messageFromServer);
        }
        if (messageFromServer.channel && messageFromServer.channel != this.channel) {//TODO should be parameter, cause leblon also can't enter here
            if (messageFromServer.action == "history" && messageFromServer.history && messageFromServer.history.length) {//TODO: check for user id, type etc
                for (const item of messageFromServer.history) {
                    if (item.action && item.action != "history") {
                        this.processMessageFromServer(item);
                    }
                }
            }
            if (messageFromServer.action != "history") {
                if (messageFromServer.action == "message") {
                    if (messageFromServer.bot_failed) {
                        this.incrementBotRetries();
                    } else {
                        this.restartBotRetries();
                    }
                }
                if (messageFromServer.actor && messageFromServer.actor != this.storage.get(storageLiterals.LAST_ACTOR)) {
                    this.onReceivedCallback({
                        action: "changedLastActor",
                        previous: this.storage.get(storageLiterals.LAST_ACTOR),
                        current: messageFromServer.actor
                    });

                    this.storage.set(storageLiterals.LAST_ACTOR, messageFromServer.actor);
                    if (messageFromServer.actor == "bot"){
                        this.onReceivedCallback({
                            action: "changedLastAgentName",
                            previous: this.storage.get(storageLiterals.LAST_AGENT_NAME),
                            current: ""
                        });
                        this.storage.set(storageLiterals.LAST_AGENT_NAME, "");
                    }
                }
                if (messageFromServer.channel && messageFromServer.channel != this.storage.get(storageLiterals.LAST_CHANNEL)) {
                    this.onReceivedCallback({
                        action: "changedLastChannel",
                        previous: this.storage.get(storageLiterals.LAST_CHANNEL),
                        current: messageFromServer.channel
                    });
                    this.storage.set(storageLiterals.LAST_CHANNEL, messageFromServer.channel);
                }
                if (messageFromServer.date && messageFromServer.date != this.storage.get(storageLiterals.LAST_RECEIVED_DATE)) {
                    this.onReceivedCallback({
                        action: "changedLastDate",
                        previous: this.date,
                        current: messageFromServer.date
                    });
                    this.storage.set(storageLiterals.LAST_RECEIVED_DATE, messageFromServer.date);
                }
                if (messageFromServer.agentName && messageFromServer.agentName != this.storage.get(storageLiterals.LAST_AGENT_NAME)) {
                    this.onReceivedCallback({
                        action: "changedLastAgentName",
                        previous: this.storage.get(storageLiterals.LAST_AGENT_NAME),
                        current: messageFromServer.agentName
                    });
                    this.storage.set(storageLiterals.LAST_AGENT_NAME, messageFromServer.agentName);
                }
            }
            if (messageFromServer.conversationId && messageFromServer.conversationId != this.storage.get(storageLiterals.CONVERSATION_ID)) {
                this.onReceivedCallback({
                    action: "changedConversationId",
                    previous: this.storage.get(storageLiterals.CONVERSATION_ID),
                    current: messageFromServer.conversationId
                });
                this.storage.set(storageLiterals.CONVERSATION_ID, messageFromServer.conversationId);
                //TODO: reset createdDate
                this.connect("CONNECT_AGAIN");
            }
        }
    }
    onReceived = (callbackFunction) => {
        if (callbackFunction) {
            this.onReceivedCallback = callbackFunction;
        }
    }

};

export {
    Lia
};

const fakePayloadForTest = {
    action: "message",
    channel: "parati", // Pode ser "parati", "lia" etc
    actor: "human", // Pode ser "bot" ou "human"
    message: "Oi, tudo bom? %%https://finanzero.com.br/%%label%% http://finanzero.com.br/ https://finanzero.com.br/ #2 %%https://finanzero.com.br/%%label%% ", //"pode ser qualquer coisa"
    agentName: "Dalton", // "Pode ser qualquer nome"
    conversationId: "128218278", // "pode ser qualquer valor"
    date: (new Date()).toISOString() // "Pode ser qualquer data no formato ISO string"
}

/**
- no primeiro "oi" do usuario, anotar liaConversationStartDate
- sempre que houve uma conexão ou reconexao, enviar evento de pedir historico
- ao receber
 */
